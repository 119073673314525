import React, { useState, useEffect, useRef } from 'react';
import './project.css';
import { projectsData } from './data';

const Project = ({ projectId = 'proshop' }) => {
  const [project, setProject] = useState(null);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Controls the display of the skeleton loader
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true); // Start with loading true
    const fetchTimeout = setTimeout(() => {
      const selectedProject = projectsData.find(p => p.id === projectId);
      setProject(selectedProject);
      setLoading(false); // Set loading to false when data is 'received'
    }, 1000); // Simulates a fetch delay, adjust time as needed

    return () => clearTimeout(fetchTimeout);
  }, [projectId]);

  useEffect(() => {
    if (!project) return;

    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex(prevIndex =>
        prevIndex === project.images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => resetTimeout();
  }, [project, index]);

  if (!project || loading) {
    return <div className='project skeleton'>Loading project...</div>;
  }

  return (
    <div
      className='project'
      id='project'
      style={{ '--num-images': project.images.length }}
    >
      <h1>
        <span className='text-red-10'>Project</span>
      </h1>
      <div className='project-details'>
        <h1>
          <span>{project.title}</span>
        </h1>
        <p className='desc'>{project.description}</p>
        <div className='project-technologies'>
          <strong>Technologies used:</strong> {project.technologies}
        </div>
        <div className='project-link'>
          <strong>Link :</strong>
          <a href={project.link} target='_blank' rel='noopener noreferrer'>
            {project.link}
          </a>
        </div>
      </div>
      <div
        className='image-container'
        style={{
          transform: `translateX(${(-index * 100) / project.images.length}%)`
        }}
      >
        {project.images.map((img, i) => (
          <img key={i} src={img.src} alt={img.alt} />
        ))}
      </div>
      <div className='buttons-container'>
        <button
          onClick={() =>
            setIndex(
              (index - 1 + project.images.length) % project.images.length
            )
          }
          className='btn'
        >
          Prev
        </button>
        <button
          onClick={() => setIndex((index + 1) % project.images.length)}
          className='btn'
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Project;
