import React, { useState, useEffect } from 'react';
import './design.css';
import { designData } from './data';

const Design = () => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    setLoading(true); // Start loading
    const timer = setTimeout(() => {
      const designProject = designData.find(p => p.id === 'design');
      setProject(designProject);
      setLoading(false); // Set loading to false once data is loaded
    }, 1000); // Simulate a fetch delay

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className='design-page'>
        <div className='design-card skeleton'>Loading design...</div>
      </div>
    );
  }

  if (!project) {
    return <div className='design-page'>Project not found.</div>;
  }

  return (
    <div className='design-page'>
      <div className='design-card'>
        <h2>{project.title}</h2>
        <p>{project.description}</p>
        <img
          src={project.images[0].src}
          alt={project.images[0].alt}
          className='design-image'
        />
        <button className='btn'>
          <a
            href={project.download}
            target='_blank'
            rel='noopener noreferrer'
            className='download-link'
          >
            PDF
          </a>
        </button>
      </div>
    </div>
  );
};

export default Design;
