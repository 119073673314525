import React, { useState } from 'react';
import './certificate.css';
import { certificatesData } from './data';

const Certificate = () => {
  const [certificate] = useState(certificatesData[0]); // Removed setCertificate

  return (
    <div className='certificate-page' id='certificate'>
      <h1>
        <span>Certificate</span>
      </h1>
      <h2>{certificate.title}</h2>
      <p className='desc'>{certificate.description}</p>
      <div className='certificate-details'>
        <img
          src={certificate.image}
          alt={certificate.title}
          className='certificate-image'
        />
        <div className='youtube-links'>
          {certificate.youtubeLinks.map((link, idx) => (
            <div key={idx} className='youtube-link-item'>
              <a href={link.url} target='_blank' rel='noopener noreferrer'>
                {link.url}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
