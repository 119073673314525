const designData = [
    {
      id: 'design',
      title: 'Design',
      name: 'Pet Central Website',
      description: 'Adobe Photoshop + Adobe Illustrator.',
      download: '../../../assets/PetCentral.pdf',
      images: [
        {
          src: '../../../assets/pet-01.png',
          alt: 'Design'
        }
      ]
    }
  ];
  
  export { designData };
  