import React, { useState } from 'react';
import './header.css';
import './blur.css'; // Importing the blur CSS for effects
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Import this package
import TypeWriter from '../../components/TypeWriter';

const Header = () => {
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);

  const handleScrollLink = e => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <header className='header-container' id='home'>
      <div className='container'>
        <div className='main-nav navbar'>
          <div className='logo'>
            <LazyLoadImage
              src='/assets/Logo/bunlong-logo.png'
              alt='logo'
              effect='blur'
              className={`img-blur ${logoLoaded ? 'loaded' : ''}`} // Conditionally apply 'loaded' class
              afterLoad={() => setLogoLoaded(true)} // Set loaded state to true after image loads
            />
          </div>
          <ul className='right'>
            <li>
              <a href='#home' onClick={handleScrollLink}>
                Home
              </a>
            </li>
            <li>
              <a href='#about' onClick={handleScrollLink}>
                About
              </a>
            </li>
            <li>
              <a href='#project' onClick={handleScrollLink}>
                Project
              </a>
            </li>
            <li>
              <a href='#certificate' onClick={handleScrollLink}>
                Certificate
              </a>
            </li>
            <li>
              <a href='#contact' onClick={handleScrollLink}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className='header-content'>
          <div className='img-content'>
            <LazyLoadImage
              src='/assets/Logo/Photo.PNG'
              alt='Profile'
              placeholderSrc='/assets/Logo/low-res-Photo.PNG'
              effect='blur'
              className={`img-blur ${profileLoaded ? 'loaded' : ''}`} // Conditionally apply 'loaded' class
              afterLoad={() => setProfileLoaded(true)} // Set loaded state to true after image loads
            />
          </div>
          <div className='content'>
            <h1 className='TypeWriter'>
              Hello, I'm <span>Bunlong Ly</span>
            </h1>
            <TypeWriter words={['Full Stack Web Developer']} wait={3000} />
            <a href='#about' className='about btn' onClick={handleScrollLink}>
              More About Me
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
